import React, { FormEventHandler } from 'react';

interface FormProps {
  children?: React.ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  autocomplete?: string;
  reference?: React.LegacyRef<HTMLFormElement>;
  className?: string;
  id?: string;
}

const Form = ({
  children,
  onSubmit,
  autocomplete,
  reference,
  className: classes,
  id,
}: FormProps): JSX.Element => {
  return (
    <form
      id={id}
      className={`form ${classes || ''}`}
      ref={reference}
      autoComplete={autocomplete}
      onSubmit={onSubmit}
    >
      {children}
    </form>
  );
};

export default Form;

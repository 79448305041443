import toast, { ToastOptions } from 'react-hot-toast';

interface ToastProps {
  message: string | JSX.Element;
  options?: ToastOptions;
}

interface AsyncToastProps<T> {
  options?: ToastOptions;
  loadingMessage?: string;
  successMessage?: string;
  errorMessage?: string;
  functionAwaitResult: Promise<T>;
  functionSuccessMessage?: (data: T) => string;
  functionErrorMessage?: (data: T) => string;
}

const optionsToast: ToastOptions = {
  style: {
    border: '1px solid #0f0701',
    padding: '16px',
    color: '#713200',
    minWidth: '250px',
  },
  duration: 7000,
};

export const customNotification = ({ message, options = optionsToast }: ToastProps): string => {
  return toast(() => message, options);
};

export const successNotification = ({ message, options = optionsToast }: ToastProps): string => {
  return toast.success(message, options);
};

export const errorNotification = ({ message, options = optionsToast }: ToastProps): void => {
  toast.error(message, options);
};

export const loadingNotification = ({ message, options = optionsToast }: ToastProps): void => {
  toast.loading(message, options);
};

export const asyncNotification = async <T>({
  loadingMessage: loading = '...Cargando',
  successMessage,
  errorMessage,
  functionAwaitResult,
  options = optionsToast,
  functionErrorMessage,
  functionSuccessMessage,
}: AsyncToastProps<T>): Promise<T> => {
  return toast.promise(functionAwaitResult, {
    loading,
    success: successMessage || functionSuccessMessage,
    error: errorMessage || functionErrorMessage,
    ...options,
  }) as Promise<T>;
};

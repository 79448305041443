import { ValidationError } from '../models/error.model';

// CONSTS
const _emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const REG_EXP_NIE = /^[XYZ]?\d{5,8}[A-Z]$/;

// METHODS
const isNumeric = (val): ValidationError => {
  if (!val) {
    return {
      OK: true,
      error: 'Este campo tiene que ser un número',
    };
  }
  const stringVal = val && val?.toString();
  return {
    OK: !(val instanceof Array) && stringVal - parseFloat(stringVal) + 1 >= 0,
    error: 'Este campo tiene que ser un número',
  };
};

const isValidDate = (value): ValidationError => ({
  OK:
    Object.prototype.toString.call(value) === '[object Date]' &&
    // eslint-disable-next-line no-restricted-globals
    !isNaN(value.getTime()),
  error: 'Esta fecha parece que no es válida',
});

/**
 *
 * VALIDATIONS
 *
 * @params:
 * value - Valor a validar
 * data - Objecto con los datos del formulario
 * @output:
 * Object:
 * OK: Bool - Define si es válido o no
 * error: String - Mensaje de error a mostrar
 */
export default {
  isRequired: (val: string | number | boolean): ValidationError => {
    const type = {}.toString.call(val);
    if (type === '[object Number]') {
      return {
        OK: val != null,
        error: 'Este campo es obligatorio',
      };
    }
    return {
      OK: !!val,
      error: 'Este campo es obligatorio',
    };
  },
  isEmail: (val: string): ValidationError => ({
    OK: _emailRegExp.test(val),
    error: 'El eMail introducido no tiene un formato válido',
  }),
  isNumeric,
  isPhone: (value): ValidationError => ({
    OK: value && value.length === 9,
    error: 'El número de teléfono debe tener 9 dígitos',
  }),
  shouldMatchElement(val, data): ValidationError {
    const inputs = null;
    if (inputs?.exportDataKeyToMatch) {
      return {
        OK: val === data[inputs?.exportDataKeyToMatch],
        error: inputs.errorMessage || 'Los campos no coinciden',
      };
    }
    return {
      OK: false,
      error: 'Must bind a object with property "exportDataKeyToMatch"',
    };
  },
  compareFields(field1, field2): ValidationError {
    if (field1 === field2) {
      return { OK: true };
    }
    return { OK: false, error: 'Las contraseñas no coinciden' };
  },
  isNIEValid(dni): ValidationError {
    const res = { OK: false, error: 'Formato de DNI/NIE incorrecto' };
    if (!dni) {
      return res;
    }
    let numero;
    let _let;
    let letra;
    const validateDni = dni.toUpperCase();
    if (REG_EXP_NIE.test(validateDni) === true) {
      numero = validateDni.substr(0, validateDni.length - 1);
      numero = numero.replace('X', '0');
      numero = numero.replace('Y', '1');
      numero = numero.replace('Z', '2');
      _let = validateDni.substr(validateDni.length - 1, 1);
      numero %= 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      res.OK = letra === _let;
      return res;
    }
    return res;
  },
  shouldBeLowerThanElement(value, data): ValidationError {
    const options = null;
    if (options.exportDataKeyOtherElement) {
      if (!options.errorMessage) {
        // console.warn(
        //   'It\'s strongly recommended set a "errorMessage" property in the binded options',
        // );
      }
      return {
        OK: value < data[options.exportDataKeyOtherElement],
        error: options.errorMessage || 'El valor debe ser menor que el del otro campo',
      };
    }
    return {
      OK: false,
      error: 'Must bind a object with property "exportDataKeyOtherElement"',
    };
  },
  shouldBeGreaterThanElement(value, data): ValidationError {
    const options = null;
    if (options.exportDataKeyOtherElement) {
      if (!options.errorMessage) {
        // console.warn(
        //   'It\'s strongly recommended set a "errorMessage" property in the binded options',
        // );
      }
      return {
        OK: value > data[options.exportDataKeyOtherElement],
        error: options.errorMessage || 'El valor debe ser mayor que el del otro campo',
      };
    }
    return {
      OK: false,
      error: 'Must bind a object with property "exportDataKeyOtherElement"',
    };
  },

  shouldBeGreaterThanNumber(number, value): ValidationError {
    return {
      OK: value.length >= number,
      error: `El campo debe de tener longitud mayor a ${number}`,
    };
  },

  shouldBeGreaterThanNumberOrNull(number, value): ValidationError {
    return {
      OK: value?.length >= number || value?.length === 0,
      error: `El campo debe de tener longitud mayor a ${number}`,
    };
  },

  shouldExistVariable(_, variable): ValidationError {
    return {
      OK: !!variable,
      error: 'Debes de seleccionar algún valor que exista',
    };
  },

  dateShouldBeLowerThanToday(value): ValidationError {
    return {
      OK: value < new Date(),
      error: 'La fecha debe ser menor que el día de hoy',
    };
  },
  positiveNumber(value): ValidationError {
    const isNum = isNumeric(value);
    if (!isNum.OK) {
      return isNum;
    }
    return {
      OK: value > 0,
      error: 'El número debe ser mayor que cero',
    };
  },
  isValidDate,
  atLeastEighteenYears(value): ValidationError {
    const isValidaDate = isValidDate(value);
    if (!isValidaDate.OK) {
      return isValidaDate;
    }
    return {
      OK: new Date().getTime() - value.getTime() > 567993600000,
      error: 'Debe ser mayor de 18 años',
    };
  },
};

/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { subscribeToNewsletterMutation } from './services/newsletter.service';
import Form from '../common/forms/Form';
import Field from '../common/forms/Field/Field';
import Button from '../common/forms/Button';
import ChooseAreas from './ChooseAreas';
import Validations from '../../utils/validations';
import { SCREEN_SIZES } from '../../utils/constants';
import useWindowSize from '../../hooks/useWindowSize';
import { Area, User } from '../../graphqlTypes';
import useFormValues from '../../hooks/useFormValues';
import { errorNotification, asyncNotification } from '../../utils/services/toast.service';
import { useReactiveVariableUser } from '../../hooks/useReactiveVariableUser';

interface NewsLetterState {
  phase1?: boolean;
  phase2?: boolean;
  phase3?: boolean;
  newsletterMail?: string;
  captcha?: boolean;
}

interface NewsLetterProps {
  user?: User;
  listAreas?: Area[];
}

const Newsletter = ({ user, listAreas }: NewsLetterProps): JSX.Element => {
  const { refetchUser } = useReactiveVariableUser();
  const { isValidate } = useFormValues();
  const { documentWidth } = useWindowSize();
  const router = useRouter();
  const emailQuery = router.query.email as string;
  const [email, setEmail] = useState<string>('');
  const [state, setState] = useState<NewsLetterState>({
    phase1: true,
    phase2: false,
    phase3: false,
    newsletterMail: null,
    captcha: true,
  });

  const policy = useRef(null);
  const communication = useRef(null);
  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);

  const nextPhase = (phase: 'phase1' | 'phase2' | 'phase3'): void => {
    setState((prevState) => ({
      ...prevState,
      phase1: phase === 'phase1',
      phase2: phase === 'phase2',
      phase3: phase === 'phase3',
    }));
  };

  useEffect(() => {
    if (!emailQuery) {
      return;
    }

    if (state.phase1) {
      if (!isValidate(!!emailQuery)) return;

      setState((prevState) => ({ ...prevState, newsletterMail: emailQuery }));
      nextPhase('phase2');
      setEmail(emailQuery);
    }
  }, [emailQuery]);

  const goToNextPhase = async (e): Promise<void> => {
    e.preventDefault();
    if (state.phase1) {
      if (!isValidate(!!email)) return;

      setState((prevState) => ({ ...prevState, newsletterMail: email }));
      nextPhase('phase2');
      return;
    }

    if (state.phase2) {
      if (!policy?.current?.checked) {
        errorNotification({ message: 'Debes aceptar las condiciones de privacidad' });

        return;
      }

      if (!communication?.current?.checked) {
        errorNotification({ message: 'Debes aceptar el envio de notificaciones.' });

        return;
      }

      if (!selectedAreas.length) {
        errorNotification({ message: 'Debes de seleccionar alguna área de interés' });
        return;
      }

      const subscribeToNewsletterPromise = subscribeToNewsletterMutation({
        email,
        _id: user ? user._id : null,
        areaOfInterest: selectedAreas,
        active: true,
      });

      await asyncNotification({
        loadingMessage: 'Guardando areas de interés',
        successMessage: 'Se ha editado su perfil correctamente',
        functionAwaitResult: subscribeToNewsletterPromise,
      });

      router.push('/confirmacion-newsletter');
      refetchUser();
    }
  };

  return (
    <>
      <div className="newsletter bordered">
        <div className="maxContainer">
          {state.phase1 ? (
            <div className="phase1 New">
              <div>
                <h2 className="headingConfianza lineh dstop">
                  NO TE PIERDAS
                  <br />
                  NUESTRAS OFERTAS
                  <br />Y NOVEDADES
                </h2>
                <h2 className="headingConfianza lineh mv">
                  NO TE PIERDAS NUESTRAS OFERTAS Y NOVEDADES
                </h2>
              </div>

              <div className="contentNewsletter">
                <div className="imgNewWrap">
                  <img src="/img/banner.svg" alt="email" className="imgBanner" />
                </div>
                <div className="contentForm">
                  <p className="subText sp">
                    SUSCRÍBETE A NUESTRA
                    <br className="br" />
                    NEWSLETTER MENSUAL
                  </p>

                  <Form className="formStyle" id="newsletter" onSubmit={goToNextPhase}>
                    <Field
                      id="email"
                      type="email"
                      placeHolder=" Tu email*"
                      validationRules={[Validations.isEmail, Validations.isRequired]}
                      propsInput={{
                        value: email,
                        onChange: setEmail,
                      }}
                    />

                    <Button iconClass="arrow-right" iconRight className="rounded" type="submit">
                      <span>Suscribirme</span>
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          ) : null}

          {state.phase2 ? (
            <div className="phase2">
              <h2>Marca todas las áreas que sean de tu interés</h2>

              <ChooseAreas
                mail={state.newsletterMail}
                listAreas={listAreas}
                onChange={setSelectedAreas}
              />

              <div className="conditionsCkeck">
                <div className="field  checkbox littleCheck">
                  <input
                    ref={policy}
                    placeholder=" "
                    className="input "
                    type="checkbox"
                    value=""
                    name="policy"
                  />

                  <label htmlFor="policy">
                    He leido y acepto las políticas de privacidad y las condiciones de servicio de
                    Axón.
                  </label>

                  <i aria-label="check" className="icon check" />
                </div>
              </div>

              <div className="conditionsCkeck">
                <div className="field  checkbox littleCheck">
                  <input
                    ref={communication}
                    aria-label="checkbox"
                    placeholder=" "
                    className="input "
                    type="checkbox"
                    value=""
                    name="communication"
                  />

                  <label htmlFor="communication">
                    Deseo recibir comunicación por parte de Axon para estar informado de las
                    novedades, ofertas, promociones y descuentos que me puedan ser de mi interés.
                  </label>

                  <i aria-label="check" className="icon check" />
                </div>
              </div>

              <div className="actionsHolder end">
                <Button className="rounded bordered cta" onClick={goToNextPhase}>
                  Finalizar sucripción
                </Button>
              </div>
            </div>
          ) : null}

          {state.phase3 ? (
            <div className="phase3">
              <h2>Gracias por suscribirte</h2>
              <Button
                noStyle={documentWidth < SCREEN_SIZES.MIN_DESK}
                iconClass="arrow-right"
                iconRight
                className="rounded"
                onClick={goToNextPhase}
              >
                <span>Cerrar</span>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Newsletter;
